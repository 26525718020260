
















































import Vue from 'vue';
import _ from 'lodash';
import { ElForm } from 'element-ui/types/form';
import { $cookies, $axios, $store } from '~/util/api';
import { ResponseCode } from '~/util/constant';

export default Vue.extend({
  data() {
    const validateUser = async (
      _rule: any,
      value: any,
      cb: (msg: string) => void
    ) => {
      if (!value) {
        this.$data.disabled = true;
        cb('请输入你的手机号或邮箱');
        return;
      }
      // if (!/(^[\w.\-]+@(?:[a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z]{2,3}$)|(^1[3|4|5|8]\d{9}$)/.test(value)) {

      if (
        !/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$|(^1[6|7|9|3|4|5|8]\d{9}$)/.test(
          value
        )
      ) {
        // if (!/^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$|(^1[6|7|9|3|4|5|8]\d{9}$)/.test(value)) {
        this.$data.disabled = true;
        cb('请输入正确的手机号或邮箱');
        return;
      }
      const res: any = await $axios.get(`/api/user/checkExist/${value}`);
      if (res.code === ResponseCode.SUCCESS) {
        if (res.data.isExist === 0) {
          this.$data.disabled = true;
          cb('该手机号或邮箱未注册');
        } else {
          this.$data.disabled = false;
        }
      }
    };

    const validateRead = (
      _rule: any,
      value: any,
      cb: (msg?: string) => void
    ) => {
      if (!value) {
        cb('请阅读并勾选服务协议');
      } else {
        cb();
      }
    };

    return {
      isEdit: false,
      loginForm: {
        userName: '',
        password: '',
        read: false,
      },
      rules: {
        userName: [{ validator: validateUser, trigger: 'change' }],
        password: [
          { required: true, message: '请输入你的密码', trigger: 'blur' },
        ],
        read: [
          {
            validator: validateRead,
            message: '请阅读并勾选服务协议',
            trigger: 'change',
          },
        ],
      },
    };
  },
  methods: {
    handleLogin() {
      let _this = this;
      const $loginForm = this.$refs.passwordForm as ElForm;
      $loginForm.validate(async (valid, err) => {
        if (!valid) {
          // 校验失败
          const msg_1 = _.get(err, 'userName[0].message', '');
          const msg_2 = _.get(err, 'password[0].message', '');
          const msg_3 = _.get(err, 'read[0].message', '');
          this.$message({
            message: msg_1 || msg_2 || msg_3 || '参数不合法',
            type: 'error',
          });
          return;
        }

        const { userName } = this.loginForm;
        const {
          origins = '',
          action = '',
          industryId = '',
        } = $store.state.user;
        const res: any = await $axios({
          url: '/api/auth/login',
          method: 'POST',
          data: {
            ...this.loginForm,
            type: 'pc',
            promotionLink: window.location.href,
          },
          headers: { origins, action, industryId },
        });

        if (res.code === ResponseCode.SUCCESS) {
          const access_token = _.get(res, 'data.jwtToken.access_token', '');
          // const expires = _.get(res, 'data.jwtToken.expires_in', 0);
          // console.log($cookies);
          // console.log('165-165', process.env.NODE_ENV); // process.env.NODE_ENV !== 'development'
          let cookieObj: any = {
            maxAge: 60 * 60 * 24 * 7,
            path: '/',
            // domain: 'jufair.com',
          };
          if (process.env.NODE_ENV !== 'development') {
            // 非开发环境时 设置一级域名cookie
            cookieObj['domain'] = 'jufair.com';
          }
          $cookies.set('phoneNumber', userName, cookieObj);
          $cookies.set('access_token', access_token, cookieObj);
          let redirect = _.get(this, '$route.query.redirect');
          if (redirect) {
            redirect = redirect;
          } else {
            redirect = '/home';
          }

          // 初始化
          await $store.commit('user/setOrigins', '');
          await $store.commit('user/setIndustryId', 0);
          await $store.commit('user/setAction', 0);
          await $store.commit('global/setIsLogin', true);
          await $store.dispatch('global/getUser');
          this.$login.hide();

          if (!$store.state.global.user.isPererffect) {
            try {
              (this.$parent?.$parent as any)?.showInfo();
            } catch (e) {
              (this.$parent?.$parent?.$parent as any)?.showInfo();
            }
          } else if (redirect) {
            // location.href = redirect;
            window.location.replace(decodeURIComponent(redirect));
          }
        }
      });
    },
    inputControl() {
      this.isEdit = true;
    },
  },
});
