


















































































import Vue from 'vue';
import _ from 'lodash';
import { ElForm } from 'element-ui/types/form';
import { FormType } from '~/pages/login/constant';
import { ResponseCode } from '~/util/constant';
import { $axios } from '~/util/api';
import slider from '../../../components/sliderImage/index.vue';

export default Vue.extend({
  components: {
    slider,
  },
  name: 'Register',
  props: {
    formTypeChange: Function,
  },
  data() {
    const validateUser = async (
      _rule: any,
      value: any,
      cb: (msg?: string) => void
    ) => {
      if (!value) {
        this.$data.disabled = true;
        cb(<string>this.$nuxt.$t('login.phoneError1'));
        return;
      }
      // if (!/(^[\w.\-]+@(?:[a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z]{2,3}$)|(^1[3|4|5|8]\d{9}$)/.test(value)) {
      // if (!/^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$|(^1[6|7|9|3|4|5|8]\d{9}$)/.test(value)) {
      if (
        !/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$|(^1[6|7|9|3|4|5|8]\d{9}$)/.test(
          value
        )
      ) {
        this.$data.disabled = true;
        cb(<string>this.$nuxt.$t('login.phoneError2'));
        return;
      }
      const res: any = await $axios.get(`/api/user/checkExist/${value}`);
      if (res.code === ResponseCode.SUCCESS) {
        if (res.data.isExist === 1) {
          this.$data.disabled = true;
          cb(<string>this.$nuxt.$t('login.phoneError3'));
        } else {
          this.$data.disabled = false;
          cb();
        }
      }
    };

    const validateRead = (
      _rule: any,
      value: any,
      cb: (msg?: string) => void
    ) => {
      if (!value) {
        cb(<string>this.$nuxt.$t('login.serviceAgreementError'));
      } else {
        cb();
      }
    };

    return {
      isEdit: false,
      FormType,
      registerForm: {
        phone: '',
        newPsd: '',
        code: '',
        read: false,
      },
      disabled: true,
      loading: false,
      btnText: this.$nuxt.$t('login.getVerificationCode'),
      rules: {
        phone: [{ validator: validateUser, trigger: 'change' }],
        newPsd: [
          {
            required: true,
            message: this.$nuxt.$t('login.passwordError1'),
            trigger: 'blur',
          },
        ],
        code: [
          {
            required: true,
            message: this.$nuxt.$t('login.codeError1'),
            trigger: 'blur',
          },
        ],
        read: [
          {
            validator: validateRead,
            message: this.$nuxt.$t('login.serviceAgreementError'),
            trigger: 'change',
          },
        ],
      },
    };
  },
  methods: {
    handleFormTypeChange(type: FormType) {
      this.$props.formTypeChange(type);
    },
    handleSendCode() {
      const $registerForm = this.$refs.registerForm as ElForm;
      $registerForm.validateField('phone', async (msg) => {
        if (msg) {
          // 校验失败
          this.$message({
            message: msg,
            type: 'error',
          });
          return;
        }
        const $sliderImage = this.$refs.sliderImage as ElForm;
        ($sliderImage as any).getDate();
      });
    },
    getCode(id: number) {
      this.sentCode(id);
    },
    async sentCode(id: number) {
      this.$data.loading = true;
      const { phone } = this.registerForm;
      const verCode = id;
      const res: any = await $axios.get(
        `/api/auth/sendRegisterVerifyCode/${phone}/${verCode}`
      );
      this.$data.loading = false;
      if (res.code === ResponseCode.SUCCESS) {
        let s = 60;
        this.disabled = true;
        const timer = setInterval(() => {
          if (s > 0) {
            this.btnText = `${s}${this.$nuxt.$t('login.getInSeconds')}`;
            s--;
          } else if (s === 0) {
            this.btnText = this.$nuxt.$t('login.getVerificationCode');
            this.disabled = false;
            clearInterval(timer); // 清除定时器
          }
        }, 1000);
      }
    },
    handleSubmit() {
      const $registerForm = this.$refs.registerForm as ElForm;
      $registerForm.validate(async (valid, err) => {
        if (!valid) {
          // 校验失败
          const msg_1 = _.get(err, 'phone[0].message', '');
          const msg_2 = _.get(err, 'newPsd[0].message', '');
          const msg_3 = _.get(err, 'code[0].message', '');
          const msg_4 = _.get(err, 'read[0].message', '');
          this.$message({
            message:
              msg_1 ||
              msg_2 ||
              msg_3 ||
              msg_4 ||
              this.$nuxt.$t('login.parameterInvalid'),
            type: 'error',
          });
          return;
        }
        const params = {
          phone: this.registerForm.phone,
          newPsd: this.registerForm.newPsd,
          code: this.registerForm.code,
          promotionLink: window.location.href,
        };
        const res: any = await $axios.post('/api/auth/forget', params);

        if (res.code === ResponseCode.SUCCESS) {
          this.$props.formTypeChange(FormType.login);
        }
      });
    },
  },
});
