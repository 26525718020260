





























import Vue from 'vue';
import { FormType, Tabs } from './constant';
import Register from './component/register.vue';
import Bind from './component/bind.vue';
import Forget from './component/forget.vue';
import Login from './component/login.vue';
import Bus from '~/components/bus';

export default Vue.extend({
  layout: 'loginLayout',
  components: {
    'ju-login': Login,
    'ju-forget': Forget,
    'ju-register': Register,
    'ju-bind': Bind,
  },
  data() {
    return {
      Tabs,
      FormType,
      formType: 0, // FormType.login, // 0:登录, 1:绑定账号, 2:忘记密码, 3:立即注册
    };
  },
  watch: {
    formType: {
      handler(val) {
        if (val === FormType.login) {
          this.$nextTick(() => {
            Bus.$emit('getTabType');
          });
        }
      },
      immediate: true,
    },
  },
  created() {
    Bus.$on('sendTabType', (val: number) => {
      if (val === Tabs.wechat) {
        Bus.$emit('fetchWxQRCode');
      }
    });
  },
  mounted() {
    // url参数: type  0:登录, 1:绑定账号, 2:忘记密码, 3:立即注册
    // url参数: tab  0:扫码登录, 1:验证码登录, 2:密码登录
    setTimeout(() => {
      let val: any = this.$route.query.type;
      if ((val ?? '') != '' && this.formType != val) {
        this.formType = Number(val);
      }
    });
  },
  methods: {
    handleFormTypeChange(type: FormType) {
      this.$data.formType = type;
    },
    showInfo() {
      this.$completeInfo.show();
    },
  },
  head() {
    return {
      title: '登录-聚展',
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: '',
        },
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
    };
  },
});
