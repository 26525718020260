
































































import Vue from 'vue';
import { ElForm } from 'element-ui/types/form';
import _ from 'lodash';
import { $cookies, $axios, $store } from '~/util/api';
import { FormType } from '~/pages/login/constant';
import { ResponseCode } from '~/util/constant';
import slider from '../../../components/sliderImage/index.vue';

export default Vue.extend({
  components: {
    slider,
  },
  name: 'Bind',
  props: {
    formTypeChange: Function,
  },
  data() {
    const validateUser = async (
      _rule: any,
      value: any,
      cb: (msg: string) => void
    ) => {
      if (!value) {
        this.$data.disabled = true;
        cb(<string>this.$nuxt.$t('login.phoneError4'));
        return;
      }
      if (!/1\d{10}/.test(value)) {
        this.$data.disabled = true;
        cb(<string>this.$nuxt.$t('login.phoneError5'));
        return;
      }
      // TODO 已绑定验证
      const res: any = await $axios.get(`/api/user/checkExist/${value}`);
      if (res.code === ResponseCode.SUCCESS) {
        // isBind:  没有绑定返回0    绑定过返回1
        if (res.data.isBind === 1) {
          this.$data.disabled = true;
          cb(<string>this.$nuxt.$t('login.phoneError6'));
        } else {
          this.$data.disabled = false;
        }
      } else {
        this.$data.disabled = false;
      }
      cb('');
    };

    return {
      isEdit: false,
      btnText: this.$nuxt.$t('login.getVerificationCode'),
      disabled: false,
      loading: false,
      FormType,
      bindForm: {
        phoneNum: '',
        code: '',
      },
      rules: {
        phoneNum: [{ validator: validateUser, trigger: 'change' }],
        code: [
          {
            required: true,
            message: this.$nuxt.$t('login.codeError1'),
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    handleFormTypeChange(type: FormType) {
      this.$props.formTypeChange(type);
    },
    handleSendCode() {
      const $bindForm = this.$refs.bindForm as ElForm;
      $bindForm.validateField('phoneNum', async (msg) => {
        // console.log(msg);
        if (msg) {
          // 校验失败
          this.$message({
            message: msg,
            type: 'error',
          });
          return;
        }
        const $sliderImage = this.$refs.sliderImage as ElForm;
        ($sliderImage as any).getDate();
      });
    },
    getCode(id: number) {
      this.sentCode(id);
    },
    async sentCode(id: number) {
      this.$data.loading = true;
      const { phoneNum } = this.bindForm;
      const verCode = id;
      const res: any = await $axios.get(
        `/api/auth/sendRegisterVerifyCode/${phoneNum}/${verCode}`
      );
      this.$data.loading = false;
      if (res.code === ResponseCode.SUCCESS) {
        let s = 60;
        this.disabled = true;
        const timer = setInterval(() => {
          if (s > 0) {
            this.btnText = `${s}${this.$nuxt.$t('login.getInSeconds')}`;
            s--;
          } else if (s === 0) {
            this.btnText = this.$nuxt.$t('login.getVerificationCode');
            this.disabled = false;
            clearInterval(timer); // 清除定时器
          }
        }, 1000);
      }
    },
    async handleSubmit() {
      const openId = $cookies.get('openId');
      const { origins = 0, action = 0, industryId = 0 } = $store.state.user;

      const res: any = await $axios({
        url: '/api/auth/bind',
        method: 'POST',
        data: {
          ...this.bindForm,
          openId,
        },
        headers: { origins, action, industryId },
      });

      if (res.code === ResponseCode.SUCCESS) {
        const access_token = _.get(res, 'data.jwtToken.access_token', '');
        // const expires = _.get(res, 'data.jwtToken.expires_in', 0);
        const { phoneNum } = this.bindForm;
        let cookieObj: any = {
          maxAge: 60 * 60 * 24 * 7,
          path: '/',
          // domain: 'jufair.com',
        };
        if (process.env.NODE_ENV !== 'development') {
          // 非开发环境时 设置一级域名cookie
          cookieObj['domain'] = 'jufair.com';
        }

        $cookies.set('phoneNumber', phoneNum, cookieObj);
        $cookies.set('access_token', access_token, cookieObj);
        let redirect = _.get(this, '$route.query.redirect');
        if (redirect) {
          redirect = redirect;
        } else {
          redirect = '/home';
        }
        // 初始化
        await $store.commit('user/setOrigins', '');
        await $store.commit('user/setIndustryId', 0);
        await $store.commit('user/setAction', 0);
        await $store.commit('global/setIsLogin', true);
        await $store.dispatch('global/getUser');

        if (redirect) {
          window.location.href = redirect;
        } else {
          this.$login.hide();
        }
      }
    },
  },
});
